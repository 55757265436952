import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getCurrentArtistDirectoryGenre } from 'state/Genres/selectors';
import { Helmet } from 'react-helmet';
import { MOST_POPULAR_ARTISTS_CATEGORY_ID } from 'state/Recs/constants';
import { translateKeywords } from 'utils/metaUtils';
import { useSelector } from 'react-redux';

function Head() {
  const genre = useSelector(getCurrentArtistDirectoryGenre);
  const translate = useTranslate();

  const genreName = genre.title.toLowerCase();

  const keywords = translateKeywords(
    translate,
    genre.id === MOST_POPULAR_ARTISTS_CATEGORY_ID
      ? 'artist radio station,artist music station,music from top artists,music from top music artists'
      : `${genreName} artists,top ${genreName} artists,best ${genreName} artists,listen to ${genreName} artists`,
  );

  const description =
    genre.id === MOST_POPULAR_ARTISTS_CATEGORY_ID
      ? translate(
          'Artist Radio gives you the power to create custom stations from your favorite songs, albums or musical artists. Listen for free on iHeart!',
        )
      : translate(
          'Create a personalized custom music station from thousands of {name} artists you love, only on iHeart!',
          { name: genre.title },
        );

  const title =
    genre.id === MOST_POPULAR_ARTISTS_CATEGORY_ID
      ? translate('Create Custom Radio Stations on Artist Radio!')
      : translate('Listen to the Best {name} Music for Free on iHeart', {
          name: genre.title,
        });

  return (
    <>
      <GenericHead
        description={description}
        metaDescription={description}
        ogType="website"
        title={title}
      />
      <Helmet
        meta={[
          {
            content: keywords,
            name: 'keywords',
          },
        ]}
      />
    </>
  );
}

export default Head;
